// React & Props
import React from "react";
import PropTypes from "prop-types";

// import LatticeGrid from "lattice/lib/components/LatticeGrid";
// import SEO from "../components/functional/seo";

function Swatch({
  children,
  name,
  activeSwatch,
  colorInfo,
  colorName,
  onClick,
  outOfStock,
  hidden,
}) {
  // const inactiveClick = e => {
  //   e.preventDefault();
  // };

  // Define background style based on pattern
  if (colorInfo && colorInfo.hex && colorName) {
    let patternStyle = null;
    if (colorInfo.pattern === "contrast") {
      patternStyle = `linear-gradient( 90deg, #${colorInfo.hex[0]}, #${colorInfo.hex[0]} 50%, #${colorInfo.hex[1]} 50% )`;
    } else if (colorInfo.pattern === "contrast-dominant") {
      patternStyle = `linear-gradient( 90deg, #${colorInfo.hex[0]}, #${colorInfo.hex[0]} 65%, #${colorInfo.hex[1]} 35% )`;
    } else if (colorInfo.pattern === "striped") {
      patternStyle = `repeating-linear-gradient(
        to right,
        #${colorInfo.hex[0]},
        #${colorInfo.hex[0]} 16.7%,
        #${colorInfo.hex[1]} 16.7%,
        #${colorInfo.hex[1]} 28.6%
      )`;
    }
    return (
      <button
        className={`swatch__button swatch__button--${name} ${
          outOfStock ? "swatch__button--oos" : ""
        } ${
          activeSwatch === children && !hidden ? "swatch__button--active" : ""
        } ${hidden ? "swatch__button--hidden" : ""}`}
        onClick={onClick}
        onKeyPress={onClick}
        type="button">
        {colorName}
        <span
          className="swatch__button--color"
          title={colorName}
          style={{
            background: patternStyle,
            backgroundColor: `#${
              !colorInfo.pattern || colorInfo.pattern === "none"
                ? colorInfo.hex[0]
                : null
            }`,
          }}
        />
      </button>
    );
  }
  return null;
}

Swatch.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  colorInfo: PropTypes.shape({
    pattern: PropTypes.string,
    hex: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
  }),
  colorName: PropTypes.string,
  outOfStock: PropTypes.bool,
  onClick: PropTypes.func,
  activeSwatch: PropTypes.string,
  hidden: PropTypes.bool,
};

Swatch.defaultProps = {
  outOfStock: false,
  onClick: () => {},
  activeSwatch: null,
  colorInfo: null,
  colorName: null,
  hidden: false,
};

Swatch.displayName = "Swatch";

export default Swatch;
