// React & Props
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Internal Components
import Swatch from "../atoms/swatch";
import CaretIcon from "../atoms/icons/caret";

// import LatticeGrid from "lattice/lib/components/LatticeGrid";
// import SEO from "../components/functional/seo";

// Utils
import { slugify, isAvailable, isOOS } from "../../utils";

function SwatchGroup({
  options,
  hideOptionTitle,
  colorInfo,
  activeIndex,
  variants,
  activeVariant,
  setActiveVariant,
  setActiveParams,
  oosQty,
  noInventory,
  size,
  hideOptions,
  minOverflow,
}) {
  const [activeSwatch, setActiveSwatch] = useState(options.values[activeIndex]);
  const [swatchExpand, setSwatchExpand] = useState(
    !(minOverflow && options.values && options.values.length >= minOverflow),
  );

  useEffect(() => {
    if (activeIndex || activeIndex === 0) {
      setActiveSwatch(options.values[activeIndex]);
    }
  }, [activeIndex]);

  const getColorInfo = (color) => {
    const ci = colorInfo.find(
      (info) => info.node.shopifyColorOption.color === color,
    );
    if (ci) {
      // Determine hex values and pattern
      // if (ci.node.pattern)
      return {
        pattern: ci.node.pattern,
        hex: [ci.node.hexValue || "ffffff", ci.node.hexValue2 || "ffffff"],
      };
    }
    return {
      hex: ["ffffff", "ffffff"],
    };
  };
  // Match colorInfo with
  return (
    <div
      className={`product-option__group swatch__group swatch__group--${slugify(
        options.name.toLowerCase(),
      )} swatch__group--${size}`}>
      {!hideOptionTitle ? (
        <p className="swatch__group-label">
          {options.name.toUpperCase()} <mark>{activeSwatch}</mark>{" "}
          {activeVariant &&
          activeVariant.title &&
          !isAvailable(activeVariant, oosQty) ? (
            <mark className="sold-out">(Sold Out)</mark>
          ) : null}
        </p>
      ) : null}
      <div
        className={`swatch__group-buttons ${
          !swatchExpand ? "swatch__group-buttons--hide-overflow" : ""
        }`}>
        {!swatchExpand ? (
          <CaretIcon
            onClick={() => setSwatchExpand(true)}
            className="swatch__expand"
            color="black300"
          />
        ) : null}
        {options.values.map((option) => (
          <Swatch
            name={options.name.toLowerCase()}
            onClick={(e) => {
              e.preventDefault();
              if (activeVariant.title) {
                // Replace active variant title with new option
                const newVariantTitle = { ...activeVariant }.title.replace(
                  activeSwatch,
                  option,
                );
                const newVariant = variants.find(
                  (v) => v.title === newVariantTitle,
                );
                if (newVariant) {
                  setActiveVariant({ ...newVariant });
                }
              }
              if (options.handlers) {
                const handle = options.handlers.find(
                  (oh) => oh.name === option,
                );
                if (handle) {
                  handle.onClick();
                }
              }
              const optionParam = setActiveParams[options.name.toLowerCase()];
              if (optionParam) {
                optionParam(option);
              }
              setActiveSwatch(option);
            }}
            activeSwatch={activeSwatch}
            hidden={
              hideOptions
                ? hideOptions.find(
                    (ho) =>
                      ho.shopifyOption && ho.shopifyOption.name === option,
                  ) !== undefined
                : false
            }
            outOfStock={isOOS({
              option,
              activeVariant,
              options,
              variants,
              oosQty,
              noInventory,
            })}
            colorInfo={getColorInfo(option)}
            colorName={option}
            key={option}>
            {option}
          </Swatch>
        ))}
      </div>
    </div>
  );
}

SwatchGroup.designSystemProps = {
  options: {
    type: "object",
    description: "Swatch options info.",
    propType: PropTypes.shape({
      name: PropTypes.string.isRequired,
      values: PropTypes.arrayOf(PropTypes.string),
      handlers: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          onClick: PropTypes.func,
        }),
      ),
    }).isRequired,
    required: true,
    subProps: {
      name: {
        type: "string",
        description: "Option name.",
        required: true,
      },
      values: {
        type: "array",
        description: "Option values.",
        required: true,
      },
      handlers: {
        type: "array",
        description: "List of handler names and click events.",
        subProps: {
          name: {
            type: "string",
            description: "Handler name.",
            required: false,
          },
          onClick: {
            type: "function",
            description: "Handler function.",
            required: false,
          },
        },
        required: false,
      },
    },
  },
  activeIndex: {
    type: "number",
    description: "0 based index of active swatch option.",
    propType: PropTypes.number,
    default: 0,
    required: false,
  },
  variants: {
    type: "array",
    description: "List of variant data for options.",
    propType: PropTypes.arrayOf(PropTypes.any),
    default: [],
    required: false,
  },
  activeVariant: {
    type: "object",
    description: "Variant info for active swatch option.",
    propType: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
    default: {},
    required: false,
    subProps: {
      id: {
        type: "string",
        description: "Variant ID.",
        required: false,
      },
      title: {
        type: "string",
        description: "Variant title.",
        required: false,
      },
    },
  },
  setActiveVariant: {
    type: "function",
    description: "Event handler when swatch option is selected.",
    propType: PropTypes.func,
    default: () => {},
    required: false,
  },
  setActiveParams: {
    type: "object",
    description: "Event handlers when swatch option URL parameter is updated.",
    propType: PropTypes.shape({
      color: PropTypes.func,
    }),
    default: {
      color: null,
    },
    required: false,
    subProps: {
      color: {
        type: "function",
        description: "Color event handler.",
        required: false,
        default: null,
      },
    },
  },
  oosQty: {
    type: "number",
    description:
      "Minimum quantity to determine if swatch option is out of stock.",
    propType: PropTypes.number,
    default: null,
    required: false,
  },
  noInventory: {
    type: "boolean",
    description:
      "True if swatch option is from a product that carries no inventory (i.e. gift card)",
    propType: PropTypes.bool,
    default: false,
    required: false,
  },
  size: {
    type: "string",
    description: `Size of swtch group ("small" | "large")`,
    propType: PropTypes.string,
    default: "large",
    required: false,
  },
  hideOptions: {
    type: "array",
    description: "List of options that will be hidden.",
    propType: PropTypes.arrayOf(
      PropTypes.shape({
        shopifyOption: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
    ),
    default: [],
    required: false,
  },
  minOverflow: {
    type: "number",
    description: "Minumum number of swatch buttons to display to hide overflow",
    propType: PropTypes.number,
    default: null,
    required: false,
  },
};

const propTypes = {};
const defaultProps = {};
Object.entries(SwatchGroup.designSystemProps).map(([k, v]) => {
  if (v.propType) {
    propTypes[k] = v.propType;
  }
  if (v.default || typeof v.default !== "undefined") {
    defaultProps[k] = v.default;
  }
  return false;
});
SwatchGroup.propTypes = { ...propTypes };
SwatchGroup.defaultProps = { ...defaultProps };

SwatchGroup.displayName = "SwatchGroup";

export default SwatchGroup;
